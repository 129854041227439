/**
 * BaseButton is not to be used directly.
 * Instead, use one of other button types that implement it.
 */

export const BUTTON_SIZE_MAP = {
	DEFAULT: `input f5 ph4`,
	SMALL: `input-small f6 ph3`,
	LARGE: `input-large f4 ph5`,
	SQUARE: 'f6 pa2 flex iconbutton',
	CIRCLE: 'f6 pa2 flex br-100 iconbutton',
	ICON: 'input f5 ph3'
};

export type ButtonSize = keyof typeof BUTTON_SIZE_MAP | null;

const PRIMARY_STYLES =
	'bg-theme-primary theme-white b--theme-primary hover-bg-theme-primary-dark hover-theme-white hover-b--theme-primary-dark active-bg-theme-primary-darker active-b--theme-primary-darker buttonprimary';

export const BUTTON_STYLE_MAP = {
	PRIMARY: PRIMARY_STYLES,
	PRIMARY_ACCENT: `${PRIMARY_STYLES} + buttonprimaryaccent`,
	SECONDARY:
		// eslint-disable-next-line max-len
		'bg-theme-white theme-grey-darker b--theme-grey-darker hover-bg-theme-white hover-theme-primary hover-b--theme-primary active-b--theme-primary-darker active-theme-primary-darker buttonsecondary',
	INTERNAL: 'bg-theme-internal theme-internal-lighter b--theme-internal-dark hover-bg-theme-internal-dark buttoninternal',
	GREY: 'bg-theme-grey theme-white b--theme-grey hover-bg-theme-grey-darker',
	ICON: 'bg-theme-white theme-grey b--theme-grey-light hover-theme-primary active-theme-primary-darker',
	DANGER: 'bg-theme-white theme-error b--theme-error hover-bg-theme-error hover-theme-white hover-b--theme-error active-b--theme-error active-theme-error buttondanger',
	INTERNAL_ICON: 'bg-theme-white active-bg-theme-white hover-bg-theme-internal-lighter theme-black bn',
	INTERNAL_SECONDARY:
		'bg-theme-white b--theme-internal-light hover-b--theme-internal active-b--theme-internal-dark theme-internal-dark active-theme-internal-darker buttoninternalsecondary'
};

export type ButtonStyle = keyof typeof BUTTON_STYLE_MAP;
