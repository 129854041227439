import React, { FunctionComponent } from 'react';
import { CART_LINK } from '../../../constants/links';
import { useCartHeader } from '../../../hooks/apollo/cart-header/cart-header.hooks';
import { Link } from '../../common-components/link/link.component';
import { LinkStyleColor } from '../../common-components/link/styled-link.component';
import { CartIcon } from '../../svg/icons.component';
import { cartIconStyles } from './cart-link.css';

const CartQuantityIndicator: FunctionComponent<{ quantity: number }> = ({ quantity }) => {
	const isOver99 = quantity > 99;
	const modifiedFontSize = isOver99 ? `f7` : null;

	return (
		<div className="h2 tc omniHomeCartIcon">
			<div
				className={`relative top-0 ${modifiedFontSize} omniHomeF5-ns`}
				style={{ left: '.15em' }}
				data-automation="header-cart-quantity-amount">
				{isOver99 ? '99+' : quantity}
			</div>
			<CartIcon className={`f2 ${cartIconStyles}`} />
		</div>
	);
};

export type CartLinkProps = {
	isMobile?: boolean;
	isBuild?: boolean;
};

// TODO: EFDC-16856: reduce use of custom css for header
export const CartLink: FunctionComponent<CartLinkProps> = ({ isMobile = false, isBuild = true }) => {
	const { quantity } = useCartHeader();
	let themeColor: LinkStyleColor;

	if (!isBuild) {
		themeColor = 'primary';
	} else {
		themeColor = isMobile ? 'white' : 'grey';
	}

	let linkStyle = '';
	if (isBuild && isMobile) {
		linkStyle = 'dn-ns bg-theme-primary pa2 order-2 h-100';
	}

	return (
		<Link
			url={CART_LINK}
			className={`${linkStyle} omniHomeCartItem`}
			color={themeColor}
			automationHook={`header-cart${isMobile ? '-mobile' : ''}`}
			ariaLabel={`View ${quantity} items currently in cart`}>
			<div className="flex items-center h-100 omniHomeHeaderIcon omniHomeMobileNavItemContent">
				<CartQuantityIndicator quantity={quantity} />
				{!isBuild && isMobile && <span className="f6 ml2 omniHomeMobileNavCartText">Cart</span>}
			</div>
		</Link>
	);
};
