/**
 * Note on the validate functions in this file.
 * It might seem weird at first that the functions return a ternary.  What this
 * is saying is simply if there is a value we will run validation on it, if
 * there is no value then it passes the validation.  This does not mean it
 * passes the `required` validation which will still run.  This also lets us
 * register form inputs as not required by simply doing something like:
 *
 * ref={register({...emailInputRegistrationConfig, required: false})}
 *
 * In this example the input would pass if there is no value but if there is a
 * value it will have to pass the validation
 */
import { validateEmail, validatePhone } from '@fergdigitalcommerce/fergy-utilities';
import { GraphQLFormattedError } from 'graphql';
import { RegisterOptions } from 'react-hook-form';
import { isDateValid } from '../../../utils/date/date.utils';
import { JAVA_MAX_INT, MIN_NEW_PASSWORD_LENGTH, MIN_ORDER_NUMBER, MIN_PASSWORD_LENGTH } from '../../constants/general';
import { MAX_PROJECT_NAME_LENGTH } from '../../constants/project/general';
import { isZipCodeValid } from '../general-helper/general-helper';

export const projectNameInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a project name.',
	maxLength: {
		value: MAX_PROJECT_NAME_LENGTH,
		message: `Project name must not be more than ${MAX_PROJECT_NAME_LENGTH} characters`
	}
};

export const emailInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter an email address.',
	validate: (value) => (value ? validateEmail(value) || 'Please enter a valid email address.' : true)
};

export const phoneInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a phone number.',
	validate: (value) => (value ? validatePhone(value) || 'Please enter a valid phone number.' : true)
};

export const zipCodeInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a United States zip code.',
	validate: (value) => (value ? isZipCodeValid(value) || 'Please enter a valid United States zip code.' : true)
};

export const zipCodeInputUsOrCaRegistrationConfig: RegisterOptions = {
	required: 'Please enter a United States or Canadian zip code.',
	validate: (value) => (value ? isZipCodeValid(value, true) || 'Please enter a valid United States or Canadian zip code.' : true)
};

export const passwordInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a password.',
	validate: (value) => (value ? value.length > MIN_PASSWORD_LENGTH || 'Please enter a valid password.' : true)
};

export const newPasswordInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a password.',
	minLength: {
		value: MIN_NEW_PASSWORD_LENGTH,
		message: `Password must have at least ${MIN_NEW_PASSWORD_LENGTH} characters`
	}
};

const isValidOrderNumber = (value) => {
	const orderNumber = Number(value);
	const isNumber = !Number.isNaN(orderNumber);
	if (isNumber) {
		return orderNumber > MIN_ORDER_NUMBER && orderNumber < JAVA_MAX_INT;
	}
	return false;
};

export const orderNumberInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter an order number.',
	validate: (value) =>
		value
			? isValidOrderNumber(value) ||
			  'Please enter a valid Order # or PO #. Your input must contain numbers only. Ignore anything before the dash in a PO number.'
			: true
};

export const dateInputRegistrationConfig: RegisterOptions = {
	required: 'Please enter a date.',
	validate: (value) => (value ? isDateValid(value) || 'Please enter a valid date.' : true)
};

export const genericRequiredFieldRegistrationConfig: RegisterOptions = {
	required: 'This field is required.'
};

/**
 * Inspects Apollo's error list for user input errors and populates a map of field names to error message.
 *
 * @param errors
 */
export const getValidationErrorsFromResponse = (errors: readonly GraphQLFormattedError[] | undefined): Record<string, string> => {
	const validationErrors = {};
	if (errors) {
		for (const error of errors) {
			if (error.extensions?.code === 'BAD_USER_INPUT') {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const invalidArgs = error.extensions?.invalidArgs as string[];
				for (const invalidArg of invalidArgs) {
					validationErrors[invalidArg] = error.message;
				}
			}
		}
	}
	return validationErrors;
};
