import { capitalize } from '@fergdigitalcommerce/fergy-utilities';
import { RefObject } from 'react';
import { ACCEPTABLE_DELAY, BASE_FONT_SIZE_PX, DOHERTY_THRESHOLD, FOCUSABLE_SELECTOR } from '../../constants/general';
import { TrackedEvent } from '../analytics/event-types';
import { AllGTMEvents, GTMEvent } from '../analytics/gtm/gtm-types';

const isNonNullable = <T>(thing: T): thing is NonNullable<T> => {
	return !!thing;
};

/**
 * Need to remove a branch from your code?  Let me do it for you!
 * This might be helpful if you have a branch which would be difficult
 * to test, i.e. `useRef` result which is nullable.
 *
 * @param thing - the thing to check
 * @param action - callback with thing typed without null/undefined
 */
export const doIfDefined = <T>(thing: T, action: (thing: NonNullable<T>) => void) => {
	if (isNonNullable(thing)) {
		action(thing);
	}
};

/**
 * Converts pixel value to rem assuming a 16px root font.
 * Used in cases when you want to use native api to get pixel values to do
 * calculations with and then set style attribute in rem.
 *
 * @param px
 */
export const pxToRem = (px: number): string => `${px / BASE_FONT_SIZE_PX}rem`;

const numberFormatter = new Intl.NumberFormat();

/**
 * Format number with commas
 *
 * @param value number to format
 */
export const formatNumber = (value: number) => numberFormatter.format(value);

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

/**
 * Format number as US dollar
 *
 * @param value dollar amount as a number
 */
export const formatPrice = (value: number | string): string => {
	if (typeof value === 'string') {
		value = parseFloat(value);
	}
	return priceFormatter.format(value);
};

const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 });

export const formatPercent = (value: number | string, returnOnInvalid = ''): string => {
	if (typeof value === 'string') {
		value = parseFloat(value);
	}

	if (!isFinite(value)) {
		return returnOnInvalid;
	}

	const percent = percentFormatter.format(value / 100);

	// This is to maintain two decimal places when number is not a
	// decimal such as "0.00%". Without this "0%" would be returned.
	return `${(Math.round(parseFloat(percent) * 100) / 100).toFixed(2)}%`;
};

export function escapeRegex(pattern: string) {
	// REGEX: escape special characters in regex.
	// Source: https://makandracards.com/makandra/15879-javascript-how-to-generate-a-regular-expression-from-a-string
	return pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

/**
 * check if window is defined.  Good for gating code that only executes on the client side
 */
export function doesWindowExist() {
	return typeof window !== 'undefined';
}

/**
 * The default isChromatic check assumes window is available
 * Our usage is universal so we need to add this thin abstraction
 *
 * @returns
 */
export function isChromatic() {
	return doesWindowExist() && (/Chromatic/.test(window.navigator.userAgent) || /chromatic=true/.test(window.location.href));
}

export function isJestRunning() {
	return doesWindowExist() && window.isJestRunning;
}

// REGEX: looks for 5 digits with `-` followed by 4(#####-####) or 5 digits or 9
const validZipCodeRegex = /^(\d{5}-\d{4}|\d{5}|\d{9})$/;

// REGEX: Canadian postal code such as M3C 1R7 (or M3C1R7 without space)
const validCanadaZipCodeRegex = /^([A-Z]\d[A-Z] ?\d[A-Z]\d)$/;

export function isZipCodeValid(zipCode, usAndCa = false) {
	return Boolean(
		zipCode && usAndCa ? validCanadaZipCodeRegex.test(zipCode) || validZipCodeRegex.test(zipCode) : validZipCodeRegex.test(zipCode)
	);
}

export const findFirstAndLastFocusable = (element: HTMLElement) => {
	// Prevent Chromatic issues with focusable elements causing flaky results
	if (isChromatic()) {
		return [];
	}
	const allFocusable = element.querySelectorAll(FOCUSABLE_SELECTOR);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return [allFocusable[0], allFocusable[allFocusable.length - 1]] as [HTMLElement, HTMLElement];
};

/**
 * Looks for a focusable within the selector or ref you provide and focuses on the
 * first focusable found.  Defaults to the popup presenters node.
 */
export const focusFirstFocusable = <T extends HTMLElement>(options: { selector?: string; ref?: RefObject<T> } = {}) => {
	const { selector = '#popup-root', ref } = options;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const container = ref ? ref.current : document.querySelector<HTMLElement>(selector);
	if (container) {
		const [focusable] = findFirstAndLastFocusable(container);
		if (focusable) {
			focusable.focus();
			return true;
		}
	}
	return false;
};

type FocusTrapOptions = {
	selector?: string;
	ref?: RefObject<HTMLInputElement>;
	// if true, restores focus to the last active element (default is true)
	restoreFocus?: boolean;
};

/**
 * This is used to keep focus in a certain region.  It does this by doing the following:
 * 1. Finds the first and last focusable elements
 * 2. Focuses the first
 * 3. Setups a listen on the last to focus the first on a forward tab (this creates the loop when tabbing)
 * 4. Returns a function that kills the listener and also returns focus to whatever was focused before the trap was setup
 */
export const setupFocusTrap = (options: FocusTrapOptions = {}) => {
	const { selector = '#popup-root', ref, restoreFocus = true } = options;
	const container: HTMLElement | null = ref ? ref.current : document.querySelector(selector);
	if (container) {
		let firstFocusable, lastFocusable;
		[firstFocusable, lastFocusable] = findFirstAndLastFocusable(container);
		if (firstFocusable) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const originalFocus = document.activeElement as HTMLElement | null;
			firstFocusable.focus();
			const tabOut = (e) => {
				/* need to re-calculate the first and last */
				[firstFocusable, lastFocusable] = findFirstAndLastFocusable(container);
				const isStillLast = e.currentTarget === lastFocusable;
				if (isStillLast) {
					if (e.key === 'Tab' && !e.shiftKey) {
						e.preventDefault();
						firstFocusable.focus();
					}
				} else {
					e.currentTarget.removeEventListener('keydown', tabOut);
					lastFocusable.addEventListener('keydown', tabOut);
				}
			};
			lastFocusable.addEventListener('keydown', tabOut);
			return () => {
				lastFocusable.removeEventListener('keydown', tabOut);
				if (originalFocus && restoreFocus) {
					originalFocus.focus();
				}
			};
		}
		return () => {};
	}
	return () => {};
};

export function scrollToElement(id: string, headerOffset = 30) {
	const element = document.getElementById(id);

	if (!element) {
		return;
	}

	const elementPosition = element.getBoundingClientRect().top;
	// Determine offset position by adding element position with pageYOffset and subtracting the fixed header offset
	const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
	const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

	// Check is "behavior: smooth" is supported by the browser
	if (supportsNativeSmoothScroll) {
		window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
	} else {
		window.scrollTo(0, offsetPosition);
	}
}

/**
 * Scrolls to a given element inside a container by id.
 */
export function scrollContainerToElement(elementId: string, containerId = '', headerOffset = 30) {
	const element = document.getElementById(elementId);
	if (element && containerId) {
		const container = document.getElementById(containerId);
		if (container) {
			container.scrollTop = element.getBoundingClientRect().top + container.scrollTop - headerOffset;
		}
	}
}

/**
 * Truncates string to particular length
 * Intended for reducing descriptions and titles to seo friendly lengths
 * options:
 *  - removeBrokenWord - removes words broken by the truncation unless there is only one word
 */
export function truncateString(str: string, limit: number, options: { removeBrokenWord?: boolean } = {}): string {
	if (options.removeBrokenWord) {
		const indexOfLastSpace = `${str} `.lastIndexOf(' ', limit);
		// if the last space doesn't exist return using regular truncation
		if (indexOfLastSpace === -1) {
			return truncateString(str, limit);
		}
		return str.substring(0, indexOfLastSpace);
	}
	return str.slice(0, limit);
}

/**
 * For debounce we do not want to exceed the Doherty threshold but we don't want
 * the debounce to be too short or it will not prevent needless calls as
 * intended. Ideally we want the doherty threshold - average response time of
 * the query in milliseconds.
 *
 * @see https://buildcom.github.io/styleguide/?path=/story/docs-best-practices--asynchronous-loading
 * @param averageResponseTime this is a tuning parameter that can be based on empirical evidence from apollo engine and/or rigor
 * @returns a number between 100 - 400 representing the debounce value in milliseconds
 */
export const inputDebounceTime = (averageResponseTime): number => {
	const time = DOHERTY_THRESHOLD - averageResponseTime;
	// return the calculated time if it is within the acceptable range
	if (time > ACCEPTABLE_DELAY && time < DOHERTY_THRESHOLD) {
		return time;
	}
	// return the floor (lower bound)
	if (time < ACCEPTABLE_DELAY) {
		return ACCEPTABLE_DELAY;
	}
	// return the ceiling (upper bound)
	return DOHERTY_THRESHOLD;
};

export const makeUnique = (list: any[]): any[] => {
	return list.reduce((result, current) => {
		return result.includes(current) ? result : [...result, current];
	}, []);
};

/**
 * Updates the JWT token
 */
export const updateJWT = (token: string) => {
	window.__AUTHTOKEN__ = token;
};

/**
 * Opens the live agent chat UI
 */
export const initiateLiveChat = (trackLiveChat: (gtmType: string) => void) => {
	if (window.embedded_svc?.inviteAPI) {
		try {
			window.embedded_svc.inviteAPI.inviteButton.acceptInvite();
		} catch (e) {
			// copying node-store logic to trap an error that is always thrown here,
			// which is related to not having an invite button tracker defined
		}
	}

	// track event
	trackLiveChat('chat-click');
};

/**
 * Tracks a live chat initiation event
 *
 */
export const makeTrackLiveChat = (trackEvent: (event: GTMEvent<AllGTMEvents>) => void): ((gtmType: string) => void) => {
	return (gtmType: string) => {
		const { page, product } = window.dataLayer;
		trackEvent({
			event: TrackedEvent.LIVE_CHAT_INTERACTION,
			interaction: gtmType,
			pageName: page,
			baseCategory: product?.baseCategory,
			businessCategory: product?.businessCategory
		});
	};
};

/**
 * Parses section values and runs validations against it. If the value is not considered valid, this method returns 0.
 *
 * @param {string} name - the name of the field
 * @param {string} value - the value from the field
 * @returns {object}
 */
export const parseValue = (name: string, value: string): object => {
	const parsedValue = parseInt(value, 10);
	return { [`${name}`]: Number.isNaN(parsedValue) ? null : parsedValue };
};

/**
 * Pluralizes a given word.
 * By default this method adds a "s" character at the end of the given word.
 * If there is a special case i.e: tomato -> tomato"es", add the word into the dictionary map (singular, plural) to handle these cases
 * on an on-demand basis.
 *
 * Important Note: Candidate to move into fergy-utilities package.
 *
 * @param {string} word
 * @param {number} quantity
 * @param {{ singular: string, plural: string }[]} [dictionary]
 * @returns {string}
 */
const pluralizationDictionary: { singular: string; plural: string }[] = [];
export const pluralize = (word: string, quantity: number, dictionary = pluralizationDictionary): string => {
	const specialWord = dictionary.find((dWord) => dWord.singular === word.toLowerCase());
	const pluralSpecialWord = specialWord ? specialWord.plural : `${word}s`;
	return quantity !== 1 ? pluralSpecialWord : word;
};

/**
 * Convert other casing to Title Case.
 * e.g. input: camel-kebab_SNAKE output: Camel Pascal Kebab Snake
 * Important Note: Candidate to move into fergy-utilities package.
 *
 * @param {string} str
 * @returns {string}
 */

export const titleCase = (str: string) => {
	if (!str) {
		return str;
	}
	return str
		.split(/\s|\.|-|_/)
		.filter((e) => e !== '')
		.map((word) => capitalize(word))
		.join(' ');
};

type ComponentType =
	| 'link'
	| 'button'
	| 'input'
	| 'checkbox'
	| 'select'
	| 'textarea'
	| 'facet'
	| 'breadcrumb'
	| 'toggle'
	| 'listitem'
	| 'radiogroup'
	| 'panel'
	| 'tab';
/**
 * Generates a data selector id
 *
 * @param {string} componentType - the type of component the id belongs to
 * @param {string} id - automation/analytics id
 */
export const generateDataSelector = (componentType: ComponentType, id: string | undefined): string | undefined => {
	return id ? `${id.split(' ').join('-').toLowerCase()}-${componentType}` : undefined;
};

/**
 * Returns true if a each individual character of a given input passed as string are all natural numbers,
 * false otherwise.
 *
 * @param {string} value
 * @returns boolean
 */
export const areAllNaturalNumbers = (value: string): boolean => {
	return value.split('').every((c) => !isNaN(parseInt(c, 10)));
};

/*
 * Configuration for building a single query string parameter.
 * Key and value represent the usual parts of a query string parameter.
 * If condition is defined and is false, then the parameter will be skipped when building the query string.
 * If encode is false, the parameter will not be URI encoded.
 */
type QueryStringBuilderParam = {
	key: string;
	value: any;
	condition?: boolean;
	encode?: boolean;
};

/**
 * Builder for constructing a query string from parts.
 */
export class QueryStringBuilder {
	private config: QueryStringBuilderParam[] = [];

	/**
	 * Construct a query string from the parts added to the builder.
	 */
	build(): string {
		const params = this.config.reduce<string[]>((result, { key, value, condition = true, encode = true }) => {
			if (key && value !== undefined && value !== null && condition) {
				result.push(`${encode ? encodeURIComponent(key) : key}=${encode ? encodeURIComponent(value) : value}`);
			}
			return result;
		}, []);
		return params.join('&');
	}

	/**
	 * Add a new parameter to the end of the builder.
	 *
	 * @param key Parameter key. Parameter will be ignored if empty.
	 * @param value Parameter value. Will be converted to a string value when building the query string.
	 * Parameter will be ignored if null or undefined.
	 * @param condition If true, include this parameter when building the query string, otherwise ignore it. Default: true
	 * @param encode If true, URI encode the key and value. Default: true
	 */
	push(key: string, value: any, condition?: boolean, encode?: boolean): QueryStringBuilder {
		this.config.push({ key, value, condition, encode });
		return this;
	}
}

export const booleanFromQueryString = (qsValue?: string | null): boolean => {
	const validValueMap = {
		'0': false,
		'1': true,
		false: false,
		true: true
	};
	return Boolean(validValueMap[qsValue ?? '']);
};

/**
 * Add a new search param to a url
 *
 * @param {string} path relative url
 * @param {string} key new param key
 * @param {string} value new param value
 * @returns {string}
 */
export function setUrlParam(path: string, key: string, value: string): string {
	// the URL constructor needs a valid base URL / host
	// TODO: if we can figure out a way to get the current host we could use appropriate absolute urls
	const parsedURL = new URL(path, 'https://www.build.com');
	parsedURL.searchParams.set(key, value);
	return `${parsedURL.pathname}${parsedURL.search}`;
}

/**
 * Converts a number to two decimal places
 */
export const toDecimal = (amount: number): number => {
	return parseFloat(amount.toFixed(2));
};

/**
 * Gets case insensitive query param
 */
export const getQueryParamValue = (searchKey: string, search: string): string | undefined => {
	const params = new URLSearchParams(search);
	let paramValue;
	params.forEach((value, key) => {
		if (key.toLowerCase() === searchKey.toLowerCase()) {
			paramValue = value;
		}
	});

	return paramValue;
};

/**
 * Removes query param from URL search param
 * For example: removeQueryParamFromSearch('query1', '?query1=test1&query2=test2')
 * would return '?query2=test2'
 */
export const removeSearchParam = (searchKey: string, search: string): string => {
	const searchParams = new URLSearchParams(search);
	searchParams.delete(searchKey);
	return searchParams.toString();
};

/**
 * Sets all the values on an object to the passed in value
 */

export function setAllObjectValues<T extends object, K extends keyof T>(obj: T, value: T[K]): T {
	const newObj = { ...obj };

	Object.keys(newObj).forEach((k) => {
		newObj[k] = value;
	});

	return newObj;
}

/**
 * Validates a youtube URL
 */
export const validateYoutubeUrl = (url: string): boolean => {
	if (url) {
		const regExp =
			/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		if (url.match(regExp)) {
			return true;
		}
	}
	return false;
};

/**
 * Generate a window listener
 */
export function generateListener(setter) {
	return function listen(mediaQueryList) {
		setter(mediaQueryList.matches);
	};
}

/**
 * Chunks an array into arrays of size `step`
 * Example: chunkEvery([1, 2, 3, 4, 5], 2) becomes [[1, 2], [3, 4], [5]]
 */
export const chunkEvery = <T>(array: Array<T>, step: number): Array<Array<T>> => {
	if (step < 1) {
		return [];
	}

	const chunker = <T>(xs: Array<T>, result: Array<Array<T>>): Array<Array<T>> => {
		if (xs.length <= step) {
			return result.concat([xs]);
		} else {
			return chunker(xs.slice(step), result.concat([xs.slice(0, step)]));
		}
	};

	return chunker<T>(array, []);
};

/**
 * Formats a boolean to a 'yes' or 'no' string
 */
export const formatBoolean = (bool: boolean) => (bool ? 'Yes' : 'No');
