import React, { FunctionComponent, useContext } from 'react';
import { OVERLAY_MARKER } from '../../../../constants/general';
import { HOMEPAGE_LINK } from '../../../../constants/links';
import { handleKeys } from '../../../../helpers/keyboard/keyboard.helper';
import { useRouteChange } from '../../../../hooks/routing/route-change.hook';
import { ClickableElement } from '../../../buttons';
import { Link } from '../../../common-components/link/link.component';
import { ProjectHeaderDropdown } from '../../../project-components/project-header-dropdown/project-header-dropdown.component';
import { OmniHomeSvg } from '../../../svg/general.component';
import { MenuIcon } from '../../../svg/icons.component';
import { CallCenterLink } from '../../call-center-link/call-center-link.component';
import { CartLink } from '../../cart-link/cart-link.component';
import { MobileAccountLink } from '../../mobile-account-link/mobile-account-link.component';
import { MobileNavContext, MobileNavProps, MobileNavProvider, NavigationUI } from '../../mobile-nav/mobile-nav.component';
import { menuButton } from '../../mobile-nav/mobile-nav.css';
import { SearchBar } from '../../search-bar/search-bar.component';
import { UniversalLocationIndicator } from '../../universal-location-indicator/universal-location-indicator.component';
import { navMenuLayout, navMenuIcon, navMenuText, divider } from './omni-home-mobile-nav.css';

// TODO: EFDC-16856: reduce use of custom css for header
export const OmniHomeMobileNavComponent: FunctionComponent<MobileNavProps> = ({ startTabName }) => {
	const { isNavigationOpen, setIsNavigationOpen, setIsTypeaheadOpen } = useContext(MobileNavContext);
	const closeNavigation = () => setIsNavigationOpen(false);

	useRouteChange(() => {
		// make sure menu is closed if the user navigates to another page
		closeNavigation();
	});

	// eslint-disable-next-line sonarjs/no-identical-functions
	const showNavigation = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.blur();
		setIsNavigationOpen(true);
	};

	return (
		<>
			{isNavigationOpen && <NavigationUI startTabName={startTabName} closeNavigation={closeNavigation} />}
			<div className="flex flex-column items-center dn-ns">
				{/* Logo */}
				<Link url={HOMEPAGE_LINK} className={`flex justify-center dn-ns w-100 tc pv2`} ariaLabel="Navigate to home page">
					<div style={{ maxWidth: '220px' }}>
						<OmniHomeSvg className="w-100 h2" />
					</div>
				</Link>
				{/* Search Bar */}
				<div className="w-100 ph3">
					<SearchBar onTypeaheadOpenChanged={setIsTypeaheadOpen} isBuildDomain={false} />
				</div>
				{/* Nav Pane */}
				<div className={`${navMenuLayout} items-center mt2 w-100`}>
					{/* Menu */}
					<ClickableElement
						className={`${menuButton} theme-primary pointer tc lh-solid active-bg-theme-grey-darker h-100 self-start omniHomeNavMenuItem`}
						onClick={showNavigation}
						onKeyDown={handleKeys([' ', 'Enter'], showNavigation)}
						ariaLabel="Site Navigation">
						<div className="flex flex-column items-center theme-primary justify-between h-100">
							<MenuIcon className={`f2 db ${navMenuIcon}`} />
							<div className={`ma0 f7 ${navMenuText}`}>Menu</div>
						</div>
					</ClickableElement>

					{/* Account */}
					<MobileAccountLink />
					{/* Projects */}
					<ProjectHeaderDropdown />
					{/* Call */}
					<CallCenterLink iconClass="f3 f5-ns pb1 pb0-ns" color="primary" />
					{/* Cart */}
					<CartLink isBuild={false} isMobile={true} />
				</div>
				{/* Divider */}
				<div className={`${OVERLAY_MARKER} db dn-ns`} />
				<div className={`w-100 mv2 ${divider}`} />
				{/* Location */}
				<UniversalLocationIndicator />
			</div>
		</>
	);
};

export const OmniHomeMobileNav: FunctionComponent<MobileNavProps> = (props) => (
	<MobileNavProvider isNavigationOpen={props.startOpen || false}>
		<OmniHomeMobileNavComponent {...props} />
	</MobileNavProvider>
);
